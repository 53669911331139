import { styled } from '@mui/system'
import { type PageProps } from 'gatsby'
import * as React from 'react'
import { Layout } from '../../../components/Layout'
import { MarriageRings } from '../../../components/MarriageRings'
import { SEO } from '../../../components/SEO'
import { Txt } from '../../../components/Txt'
import { useMarriageRingParams } from '../../../hooks/useMarriageRingParams'

const DESCRIPTION =
    '3万円台からの結婚指輪は低価格・高品質のミスプラチナ。渋谷ショールームでご試着、またはサンプルレンタルも無料でご利用頂けます♪'
export const Head = () => (
    <SEO
        title="安い結婚指輪・激安マリッジリング（3万円以上）をお探しなら｜ミスプラチナ"
        description={DESCRIPTION}
        page="marriagering/price/3万円以上"
    />
)

const ThreePage: React.FC<PageProps> = ({ location }) => {
    const title = (
        <H1>
            <Txt size="l">3万円台以上</Txt>
            <Txt size="s">の結婚指輪を探す</Txt>
        </H1>
    )
    const params = useMarriageRingParams(location.search)
    return (
        <Layout
            breadcrumbs={[
                { name: '安い結婚指輪・マリッジリング', path: 'marriagering/' },
                { name: '値段から探す', path: 'marriagering/price/' },
                { name: '3万円台以上' },
            ]}
        >
            <MarriageRings
                title={title}
                description={DESCRIPTION}
                price
                {...params}
                three
            />
        </Layout>
    )
}

const H1 = styled('h1')({
    margin: '0 0 8px',
    lineHeight: 1,
})

export default ThreePage
